@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat"),
    url("./fonts/Montserrat/static/Montserrat-Black.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.montserrat-black{
  font-family: Montserrat-Black;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1410px;  
  }
}

