* {
  max-width: 100vw;
  box-sizing: border-box;
}

.swiper.swiper-horizontal {
  margin-left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr auto auto 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    "slider slider slider slider slider slider"
    "left left page page right right";

  .swiper-wrapper {
    grid-area: slider;
  }

  .swiper-button-prev {
    grid-area: left;
    order: 1;
    background-image: url("../public/arrow.svg");
    width: 28px;
    height: 16px;
    transform: scale(-1, 1);
    justify-self: flex-end;
    align-self: center;
    cursor: pointer;

    &.swiper-button-disabled {
      visibility: hidden;
    }
  }

  .swiper-button-next {
    grid-area: right;
    order: 3;
    background-image: url("../public/arrow.svg");
    width: 28px;
    height: 16px;
    justify-self: flex-start;
    align-self: center;
    cursor: pointer;

    &.swiper-button-disabled {
      visibility: hidden;
    }
  }

  .swiper-pagination {
    grid-area: page;
    order: 2;
    display: flex;
    align-items: center;
    height: 32px;
    margin: 16px 16px;
    align-self: center;

    .swiper-pagination-bullet {
      height: 32px;
      width: 32px;
      padding: 12px;
      border-radius: 50%;
      cursor: pointer;

      &::before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #000;
        border-radius: 50%;
      }

      &.swiper-pagination-bullet-active {
        border: 1px solid #000;
        padding: 11px;

        &::before {
          background-color: #1123fa;
        }
      }
    }
  }
}

.quote {
  font-style: italic;

  &::before,
  &::after {
    content: "";
    display: block;
    background-color: #1123fa;
    height: 4px;
    width: 80px;
    margin: 40px 0;
  }
}

.calculator{
  input::selection{
    background-color: black;
    color: #101010;
  }
}