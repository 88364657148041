.accordion{
    .headline{

        &::after{
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background: #000;
            flex-shrink: 0;
        }

        &.active{

            &::after {
                background: transparent;
                border: 2px solid #000;
            }
        }
    }
}