.button{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    max-width: 100%;
    text-align: center;
    border-radius: 12px;
    padding: 8px 16px;
    font-size: 22px;
    line-height: 26.82px;

    &-primary{
        background-color: #1123FA;
        color: #fff;

        &:hover{
            background-color: #5D69FC;
        }
    }

    &-outline{
        background-color: transparent;
        color: #000;
        border: 1px solid #000;

        &:hover{
            background-color: rgba(128,128,128,0.1);
        }
    }

    &-outline-reverse{
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;

        &:hover{
            background-color: rgba(128,128,128,0.1);
        }
    }
}