.toggle-wrapper{

    .toggle{
        display: block;
        width: 54px;
        height: 26px;
        border-radius: 13px;
        background: #fff;
        position: relative;

        &::after{
            content: "";
            position: absolute;
            top: 0;
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #0000ff;
            margin: 1px;
        }
    }

    .toggle-checkbox {
        opacity: 0;
        width: 0px;
        height: 0px;

        + .toggle{
            &::after {
                left: 0;
            }
        }

        &:checked{
            + .toggle{
                &::after {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}